import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import * as AcfLayout from '../acf';
import SEO from '../components/SEO';
import Previewable from '../components/Previewable';
import { decodeEntities } from '../utils/htmlParse';

const AcfComponent = ({ item, location, siteMetadata, parent, componentName }) => {
  const ComponentName = AcfLayout[componentName];
  return (
    <ComponentName
      parent={parent}
      location={location}
      siteMetadata={siteMetadata}
      {...item}
    />
  );
};

export const PageTemplate = ({ title, content }) => {
  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
}

const Page = (props) => {
  const {
    data,
    location
  } = props;
  const {
    wordpressPage: page,
    site
  } = data;
  if (!page) return null;
  const {
    title,
    content,
    parent,
    yoast,
    acf = {}
  } = page;
  const { siteMetadata } = site;
  const { title: siteTitle } = siteMetadata;
  const standardHeroImage =
    acf.layout.find(block => block.__typename === 'WordPressAcf_StandardHero') ?
      (acf.layout.find(block => block.__typename === 'WordPressAcf_StandardHero').image || null) : null;
  return (
    <Layout location={location}>
      <SEO
        title={yoast.metaTitle ? `${decodeEntities(yoast.metaTitle)}` : `${decodeEntities(title)} | ${siteTitle}`}
        desc={yoast.metaDescription}
        banner={standardHeroImage && standardHeroImage.source_url}
      />
      {(acf && acf.layout) ? acf.layout.map((item, index) => {
        if (!item.__typename) return null;
        const layoutComponentName = item.__typename.replace('WordPressAcf_', '');
        return (
          <AcfComponent
            key={item.id || `layoutComponentName-${index}`}
            componentName={layoutComponentName}
            item={item}
            location={location}
            parent={parent}
            siteMetadata={siteMetadata}
          />
        );
      }) :
        <PageTemplate title={title} content={content} />
      }
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Previewable(Page)

export const pageQuery = graphql`
query PageById($id: String!) {
  site {
    siteMetadata {
      title
      wordpressUrl
      blogSlug
      siteUrl
      functionsUrl
    }
  }
  wordpressPage(id: { eq: $id }) {
    title
    content
    parent: parent_element {
      slug
      title
    }
    yoast {
      metaTitle: title,
      metaDescription: metadesc
    }
    acf {
      layout: layout_page {
        __typename,
        ... on WordPressAcf_AccordionBlock {
          id
          isOpenOnLoad
          blocks {
            blockTitle
            sections {
              type
              heading
              textPosition
              text
              leftColHeading
              leftColumnText
              rightColHeading
              rightColumnText
              image {
                id
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 70) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
                alt_text
              }
              table {
                header {
                  c
                }
                body {
                  c
                }
              }
              cssClassName
            }
          }
        }
        ... on WordPressAcf_CenteredTextBlock {
          id
          theme
          heading
          text
        }
        ... on WordPressAcf_ContactFormBlock {
          id
          heading
          subheading
          contactFormHeading
        }
        ... on WordPressAcf_ContactInformationBlock {
          id,
        }
        ... on WordPressAcf_ContactIntroBlock {
          id
          heading
          sideHeading
          text
        }
        ... on WordPressAcf_FullImageTextBlock {
          id
          theme
          textPosition
          heading
          text
          image {
            id,
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            alt_text,
          }
        }
        ... on WordPressAcf_HomeHero {
          id,
          images {
            dummy,
            image {
              id,
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              },
              alt_text,
            }
          }
          heading
          subheading
          hasLink
          link
          linkLabel
        }
        ... on WordPressAcf_HomeIntro {
          id
          heading
          text
          image {
            id,
            localFile {
              childImageSharp {
                fluid(maxWidth: 600, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            alt_text,
          }
        }
        ... on WordPressAcf_ImageFormBlock {
          id
          heading
          subheading
          formPosition
          image {
            id
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt_text
          }
        }
        ... on WordPressAcf_ImageLinksBlock {
          id,
          links {
            link
            linkLabel
            image {
              id,
              localFile {
                childImageSharp {
                  fluid(maxWidth: 430, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on WordPressAcf_ImageTextBlock {
          id
          theme
          textPosition
          heading
          text
          image {
            id,
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            },
            alt_text,
          }
        }
        ... on WordPressAcf_LogosBlock {
          id
          heading
          usingGlobalLogos
          logos {
            logo {
              id
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 200, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
        ... on WordPressAcf_MapBlock {
          id
          map {
            address
            lat
            lng
          }
        }
        ... on WordPressAcf_NewsBlock {
          id
          heading
          items {
            heading
            text
            link
            image {
              id,
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          hasSlant
          slantColor
          slantDirection
        }
        ... on WordPressAcf_ServiceLinksBlock {
          id
          links {
            heading
            text
            link
            icon {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
              alt_text
            }
          }
        }
        ... on WordPressAcf_ShowcaseBlock {
          id,
          hasShowcaseHeading
          showcaseHeading
          showcaseItems {
            heading
            subheading
            hasLink
            link
            image {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              },
              alt_text
            }
          }
          heading
          text
          hasSlant
          slantColor
          slantDirection
        }
        ... on WordPressAcf_SliderBlock {
          id
          heading
          subheading
          slides {
            heading
            hasLink
            link
            linkLabel
            image {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              },
              alt_text
            }
            points {
              text
            }
          }
        }
        ... on WordPressAcf_SocialShareBlock {
          id,
        }
        ... on WordPressAcf_StandardHero {
          image {
            id
            source_url
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 70) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt_text
          }
          hasText
          heading
          text
          hasLink
          link
          linkLabel
        }
        ... on WordPressAcf_StandardTextBlock {
          id,
          theme
          mainHeading
          text
          hasLink
          link
          linkLabel
        }
        ... on WordPressAcf_SubscribeFormBlock {
          id,
        }
        ... on WordPressAcf_TabLinks {
          id
          links {
            link
            linkLabel
            image {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt_text
            }
          }
        }
        ... on WordPressAcf_TestimonialsBlock {
          id
          hasLink
          link
          linkLabel
        }
        ... on WordPressAcf_ThreeColumnTextBlock {
          id
          theme
          columnOneHeading
          columnOneContentType
          columnOneText
          columnOnePoints {
            point
          }
          columnTwoHeading
          columnTwoContentType
          columnTwoText
          columnTwoPoints {
            point
          }
          columnThreeHeading
          columnThreeContentType
          columnThreeText
          columnThreePoints {
            point
          }
        }
        ... on WordPressAcf_VideoBlock {
          id
          heading
          videoUrl
          videoProvider
        }
      }
    }
  }
}
`
